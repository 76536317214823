<template>
  <div class="news-container">
    <div class="title fs-24 fc-1">新闻资讯</div>
    <div class="data-table">
      <div
        class="news-item flex"
        v-for="(item, index) in dataList"
        :key="index"
        @click="goDetail(item)"
      >
        <img :src="item.cover" class="news-img" />
        <div class="info">
          <div class="title fs-14 fc-3">{{item.title}}</div>
          <div class="date fs-14 fc-9">{{item.created_at}}</div>
          <div class="des fs-14 fc-6 lm-2">{{item.description}}</div>
        </div>
      </div>
    </div>
    <div class="pagination flex" v-show="total > 0">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page.sync="page"
        :total="total"
        :page-size="pageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API } from "@/const/const";

export default {
  name: "newsIndex",
  data() {
    return {
      pageSize: 3,
      page: sessionStorage.getItem("newsListPage") || 1,
      total: 0,
      dataList: sessionStorage.getItem("newsList")
        ? JSON.parse(sessionStorage.getItem("newsList"))
        : []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    getData() {
      request
        .get(API.GET_NEWS_LIST, {
          page_size: this.pageSize,
          page: this.page
        })
        .then(res => {
          sessionStorage.setItem("newsList", JSON.stringify(res.data));
          sessionStorage.setItem("newsListPage", res.page)
          this.dataList = res.data;
          this.page = res.page;
          this.total = res.total;
        });
    },
    goDetail(item) {
      this.$router.push({ path: `/newsDetail?id=${item.id}` });
    }
  }
};
</script>

<style lang="less" scoped>
.news-container {
  padding: 0.56rem 2.8rem 0;
  .data-table {
    padding-bottom: 0.4rem;
    .news-item {
      padding: 0.23rem 0;
      border-bottom: 0.01rem solid #eee;
      .news-img {
        width: 2rem;
        height: 1.2rem;
      }
      .info {
        cursor: pointer;
        margin-left: 0.16rem;
        flex: 1;
        .title {
          font-weight: 500;
        }
        .date {
          padding: 0.1rem 0 0.08rem;
        }
      }
    }
  }
  .pagination {
    justify-content: center;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: var(--common-red);
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: var(--common-red);
    }
    /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active:hover {
      color: #fff;
    }
  }
}
</style>